import React from "react"
import "./Contact.css"
const Contact = () => {
  return (
    <div className="mainContact" id="contact">
      <div className="my-container">
        <div className="textStyle">
          <h1>Don't see your institute?</h1>
          <p>Leave us a request and we'll get back to you!</p>
        </div>
        <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfnc7MJNI0LoXeu9hy75SL9j2WVS2vY0grnzk_RA0pP4tAmaw/formResponse">
          <label>Your Name</label>
          <input required type="text" id="name" name="entry.2005620554" />
          <label>Email address</label>
          <input required type="email" id="email" name="entry.1045781291" />
          <label>Organization Name</label>
          <input
            required
            type="text"
            id="organization"
            name="entry.1065046570"
          />
          <label>Message</label>
          <textarea
            name="entry.839337160"
            rows={3}
            cols={70}
            id="subject"
            placeholder="Write a Message.."
            // style={{ height: "200px" }}
          ></textarea>

          <input type="submit" value="Submit"></input>
        </form>
      </div>
    </div>
  )
}
export default Contact
